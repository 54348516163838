const initState = {
    showDatepicker:false
}


const timeControllReducer = (state = initState, action) => {

    switch(action.type){
        
        case 'TOGGLE_DATEPICKER':
            return {
                ...state,
                showDatepicker: !state.showDatepicker
            }
        default: 
            return state;
    }
    
}

export default timeControllReducer