const getFromStorage = (field) => {

    if(localStorage.getItem('remember')){
        
        if(localStorage.getItem(field) !== null){
            return JSON.parse(localStorage.getItem(field))
        }
    }
    else {

        if(sessionStorage.getItem(field) !== null){
            return JSON.parse(sessionStorage.getItem(field))
        }
    }

    return null;
}

const initState = {
    authLoading: false,
    authError: null,
    user: getFromStorage('user'),
    tokens: getFromStorage('tokens'),
    loggedIn: getFromStorage('tokens') !== null ? true : false
}

const authReducer = (state = initState, action) => {

    switch(action.type){
        case 'LOGIN_STARTED':
            return {
                ...state,
                authLoading: true
            }
        case 'LOGIN_ERROR':
            return {
                ...state,
                authLoading: false,
                authError: (action.err.response !== undefined && action.err.response.data !== undefined ? action.err.response.data : "Internal Error"),
                user: null,
                tokens: null,
                loggedIn: false
            }

        case 'LOGIN_SUCCESS':
            return {
                ...state,
                authLoading: false,
                authError: null,
                user: action.data.user,
                tokens: action.data.tokens,
                loggedIn: true
            }
        case 'REFRESH_TOKEN':
            return {
                ...state,
                tokens: action.data,
            }
        case 'LOGOUT':
            return {
                ...state,
                authLoading: false,
                authError: null,
                user: null,
                tokens: null,
                loggedIn: false
            }
        default: 
            return state;
    }
    
}



export default authReducer