import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSensorDataInterval, getSensorDataNow, setTimestamp, setDisplaySpeed, resetSensorData, getSensorLocations,setFromAlarm, resetOldAlarm } from '../../store/actions/sensorActions';
import { getWeatherData } from '../../store/actions/weatherActions';
import { toggleDatepicker } from '../../store/actions/timeControllActions';
import { getUncheckAlarms } from '../../store/actions/alarmActions';

import SpeedControll from '../dashboard/SpeedControll';
import DateTimeSelector from '../dashboard/DateTimeSelector';

class FooterMenu extends Component {

    state = {
        interval: null,
        speed: null,
        dateselector: false,
        mapMode: 'Real-Time',
        historicalText: 'Go to Historical',
        needRefreshChart: 1, // refresh needed in 60*15
        lastStatus: -1
    }

    componentDidMount(){

        this.setState({
            interval: setInterval(() => this.refreshData(), 1000),
            speed: this.props.sensorDisplayChoosed
        })

        this.props.getSensorLocations(Math.round(new Date().getTime()/1000))

        if(this.props.sensorFromAlarm === false){
            const interval = {
                timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
                timestamp_to: Math.round(new Date().getTime()/1000),
            }
    
            if(this.props.sensorTimestamp === null || interval.timestamp_to - this.props.sensorTimestamp < 60){
                this.props.getUncheckAlarms()
                this.props.getSensorDataInterval(interval)
                this.props.getSensorDataNow({ timestamp: interval.timestamp_to })
                this.props.getWeatherData({ timestamp: interval.timestamp_to })
                this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })
            }
        }
        else {
            // disable commming from alarm page
            this.props.setFromAlarm(false)
        }       
    }

    componentWillReceiveProps(){

        if(this.props.sensorCloudTimestamp >= Math.floor(Date.now() / 1000)-60){
            this.setState({mapMode:'Real-Time'});
            
            if(this.props.showDatepicker === true){
                this.setState({historicalText:'Close date selector'});
            }
            else{
                this.setState({historicalText:'Go to Historical'});
            }

        }else{
            this.setState({mapMode:'Historical'});

            if(this.props.showDatepicker === true){
                this.setState({historicalText:'Close date selector'});
            }
            else{
                this.setState({historicalText:'Open Historical date'});
            }
        }
    }

    refreshData = () => {

        // if speed is 0 then no need to update interface
        if(this.props.sensorDisplayChoosed === 0){
            return
        }

        // changed speed
        if(this.state.lastStatus !== -1 && this.props.sensorDisplayChoosed !== this.state.lastStatus && this.props.sensorDisplayChoosed !== 2){
            this.props.resetSensorData();

            const interval = {
                timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
                timestamp_to: Math.round(new Date().getTime()/1000),
            }

            this.props.getSensorLocations(interval.timestamp_to)
            this.props.getSensorDataInterval(interval)
            this.props.getSensorDataNow({ timestamp: interval.timestamp_to })
            this.props.getWeatherData({ timestamp: interval.timestamp_to })
            this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })
        }
        this.setState({lastStatus: this.props.sensorDisplayChoosed })

        // if speed is 1 is real-time might need refresh chart
        if(this.props.sensorDisplayChoosed === 1){

            const refreshIn = 15*60; // 15min
            const needRefresh = this.state.needRefreshChart + 1 === refreshIn ? true : false; 

            // console.log(this.state.needRefreshChart + 1, Math.round(new Date().getTime()/1000), this.props.sensorTimestamp, this.props.sensorCloudTimestamp)
            
            if(needRefresh){
                this.props.resetOldAlarm()
                this.setState({needRefreshChart: this.state.needRefreshChart + 1})

                const interval = {
                    timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
                    timestamp_to: Math.round(new Date().getTime()/1000),
                }
                
                this.props.getSensorLocations(interval.timestamp_to)
                this.props.getSensorDataInterval(interval)
                this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })
                this.setState({needRefreshChart:0})
            }
            else {
                // prevent delay and empty graphs on normal user
                const now =  Math.round(new Date().getTime()/1000)

                // if more than 30s of delay refresh all data and set new timestamps
                if(now - this.props.sensorTimestamp > 30 || (this.props.sensorDataInterval == null && !this.props.sensorLoading)){
                    const interval = {
                        timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
                        timestamp_to: Math.round(new Date().getTime()/1000),
                    }
                    
                    this.props.getSensorLocations(interval.timestamp_to)
                    this.props.getSensorDataInterval(interval)
                    this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })
                }
            }
        }
        else {
            this.setState({needRefreshChart:0})
        }

        const now = Math.round(new Date().getTime()/1000);
        const nextTimestamp = this.props.sensorTimestamp + this.props.sensorDisplaySpeed[this.props.sensorDisplayChoosed].speed
        const nextCloudTimestamp = this.props.sensorCloudTimestamp + this.props.sensorDisplaySpeed[this.props.sensorDisplayChoosed].speed

        if(nextCloudTimestamp > now){
            this.props.setDisplaySpeed({ sensorDisplayChoosed: 1 })

            this.props.setTimestamp({ timestamp: now, cloudTimestamp: now, nextTimestamp: this.props.sensorNextUpdate})
            this.props.getSensorDataNow({ timestamp: now })
            this.props.getWeatherData({ timestamp: now })

        }
        else if( nextCloudTimestamp >= this.props.sensorNextUpdate){

            this.props.getUncheckAlarms()

            if(this.props.sensorCloudTimestamp >= this.props.sensorTimestamp){

                this.props.setTimestamp({ timestamp: this.props.sensorNextUpdate, cloudTimestamp: this.props.sensorNextUpdate, nextTimestamp: this.props.sensorNextUpdate  + this.props.sensorUpdateInterval})
                this.props.getSensorDataNow({ timestamp: this.props.sensorNextUpdate, update_interval: true })
                this.props.getSensorDataNow({ timestamp: this.props.sensorNextUpdate })
                this.props.getWeatherData({ timestamp: this.props.sensorNextUpdate })                
            }
            else {
                if( nextTimestamp <= now){
                    this.props.getSensorLocations(this.props.sensorNextUpdate)
                    this.props.setTimestamp({ timestamp: nextTimestamp, cloudTimestamp: this.props.sensorNextUpdate, nextTimestamp: this.props.sensorNextUpdate  + this.props.sensorUpdateIntervalPlayback })
                    this.props.getSensorDataNow({ timestamp: nextTimestamp, update_interval: true })

                }
                else {
                    this.props.setTimestamp({ timestamp: this.props.sensorTimestamp, cloudTimestamp: this.props.sensorNextUpdate, nextTimestamp: this.props.sensorNextUpdate  + this.props.sensorUpdateIntervalPlayback})
                    this.props.getSensorDataNow({ timestamp: this.props.sensorTimestamp, update_interval: true })
                }

                this.props.getSensorDataNow({ timestamp: nextCloudTimestamp })
                this.props.getWeatherData({ timestamp: nextCloudTimestamp })                
            }
           
        }
        else{
            this.props.setTimestamp({ timestamp: nextTimestamp,  cloudTimestamp: nextCloudTimestamp, nextTimestamp: this.props.sensorNextUpdate })
        }

    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    timestampToString(timestamp){
        const date = new Date(timestamp *1000)
        const year = date.getFullYear()
        const month = ("0" + (date.getMonth() + 1)).slice(-2) 
        const day = ("0" + date.getDate()).slice(-2)

        const hours = ("0" + date.getHours()).slice(-2) 
        const minutes = ("0" + date.getMinutes()).slice(-2)
        const seconds = ("0" + date.getSeconds()).slice(-2)

        return (year + "/" + month + "/" +day + " " + hours + ":" + minutes + ":" + seconds)
        // return (hours + ":" + minutes + ":" + seconds)

    }

    getTimeLabels = () => {

        // if historical data
        if(this.props.sensorCloudTimestamp < this.props.sensorTimestamp){
            return (
                <table className="mx-2 mt-1 mx-auto">
                    <tbody>
                        <tr>
                            <td className="px-1 text-center" style={{ lineHeight:"12px" }}>  Playback Time </td>
                        </tr>
                        <tr>
                            <td className="px-3">  
                                <span className="h5">{ this.props.sensorCloudTimestamp !== undefined ? this.timestampToString(this.props.sensorCloudTimestamp) : null } </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        }
        else {
            return (
                <table className="mx-2 mt-1 mx-auto">
                    <tbody>
                        <tr>
                            <td className="px-1 text-center" style={{lineHeight:"12px"}}>  Current Time </td>
                            <td className="px-1 text-center" style={{lineHeight:"12px"}}>  Next Update  </td>
                        </tr>
                        <tr>
                            <td className="px-3">  
                                <span className="h5">{ this.props.sensorCloudTimestamp !== undefined ? this.timestampToString(this.props.sensorCloudTimestamp) : null } </span>
                            </td>
                            <td className="px-3"> 
                                <span className="h5">{ this.props.sensorNextUpdate !== undefined ? this.timestampToString(this.props.sensorNextUpdate) : null } </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        } 
    }

    goToRealTime(){
        const interval = {
            timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
            timestamp_to: Math.round(new Date().getTime()/1000),
        }

        this.props.getSensorDataInterval(interval)
        this.props.getSensorDataNow({ timestamp: interval.timestamp_to })
        this.props.getWeatherData({ timestamp: interval.timestamp_to })                

        this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })

        this.props.setDisplaySpeed({ sensorDisplayChoosed: 1}) 
    }

    render(){

        return (
            <div className="footer bg-dark text-light py-1">
                
                { this.props.showDatepicker ? <DateTimeSelector /> : null }

                <div className="row px-3 text-center">
                    <div className="col-4 pt-1">      
      
                        { this.props.loggedIn === true && this.props.user && this.props.user.role !== null && this.props.user.role.name !== 'User'
                            ? 
                            <>
                                <button className="btn btn-info mr-2" onClick={()=> this.goToRealTime()}  disabled={this.state.mapMode === 'Real-Time'} > Go to Real-Time </button>
                                <button className="btn btn-info" onClick={ () => this.props.toggleDatepicker() }> 
                                    { this.state.historicalText } 
                                </button>
                            </>
                            :
                            null
                        }

                    </div>
                    <div className="col-4">
                        { this.getTimeLabels() }
                    </div>
                    <div className="col-4 pt-1">
                        {  this.props.loggedIn === true && this.props.user && this.props.user.role !== null && this.props.user.role.name !== 'User'  && this.props.sensorCloudTimestamp < this.props.sensorTimestamp ? <SpeedControll /> : null}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sensorLoading: state.sensor.sensorLoading,
        sensorDataInterval: state.sensor.sensorDataInterval,
        sensorDataNow: state.sensor.sensorDataNow,
        sensorTimestamp: state.sensor.sensorTimestamp,
        sensorCloudTimestamp: state.sensor.sensorCloudTimestamp,
        sensorNextUpdate: state.sensor.sensorNextUpdate,
        sensorDisplayChoosed: state.sensor.sensorDisplayChoosed,
        sensorDisplaySpeed: state.sensor.sensorDisplaySpeed,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval,
        sensorUpdateIntervalPlayback: state.sensor.sensorUpdateIntervalPlayback,
        sensorFromAlarm: state.sensor.sensorFromAlarm,
        showDatepicker: state.timeControll.showDatepicker,
        user: state.auth.user,
        loggedIn: state.auth.loggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval : (params) => dispatch( getSensorDataInterval(params) ),
        getSensorDataNow : (params) => dispatch( getSensorDataNow(params) ),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        setDisplaySpeed : (params) => dispatch( setDisplaySpeed(params) ),
        toggleDatepicker : () => dispatch( toggleDatepicker() ),
        resetSensorData: () => dispatch( resetSensorData() ),
        getSensorLocations: (params) =>  dispatch( getSensorLocations(params) ),
        getWeatherData: (params) => dispatch(getWeatherData(params)),
        getUncheckAlarms : () => dispatch( getUncheckAlarms() ),
        setFromAlarm : (params) => dispatch( setFromAlarm(params) ),
        resetOldAlarm : () => dispatch( resetOldAlarm() )
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (FooterMenu);