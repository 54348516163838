const initState = {
    width_ratio: null,
    height_ratio: null,
    centerShift_x: null,
    centerShift_y: null
}


const mapReducer = (state = initState, action) => {

    switch(action.type){
        
        case 'SET_MAP_DATA':
            return {
                ...state,
                width_ratio: action.data.width_ratio,
                height_ratio: action.data.height_ratio,
                centerShift_x: action.data.centerShift_x,
                centerShift_y: action.data.centerShift_y,
            }
        default: 
            return state;
    }
    
}

export default mapReducer