export const setMapData = ( width_ratio, height_ratio, centerShift_x, centerShift_y ) => {
    
    return dispatch => {

        const map_data = {
            width_ratio: width_ratio,
            height_ratio: height_ratio,
            centerShift_x: centerShift_x,
            centerShift_y: centerShift_y
        }

        dispatch({ type: 'SET_MAP_DATA', data: map_data });
    };

};
