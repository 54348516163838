const initState = {
    sensorLoading: false,
    sensorError: null,
    sensorState: null,
    sensorDataNow: null,
    sensorDataInterval: null,
    sensorTimestamp:  null, // change this to now
    sensorCloudTimestamp:  null, // timestamp to draw the cloud
    sensorNextUpdate: null, // timestamp of next update
    sensorDisplayChoosed: 1, // 1x speed
    sensorDisplaySpeed: { 
        0: { label: "Pause", speed:0 }, 
        1: { label: "1s", speed:1 }, 
        2: { label: "15m", speed:15*60 }
    },
    sensorLocations: null,
    sensorUpdateInterval: 1 * 60, // update interval in seconds,
    sensorUpdateIntervalPlayback: 15 * 60, // update interval in seconds for playbak
    sensorChartInterval: null,
    sensorChartMax: 0,
    sensorFromAlarm: false, // came from alarm page
    sensorOldAlarms: [],
    sensorExistAlarm: false
}


const sensorReducer = (state = initState, action) => {

    const removeOld = (entry) => {

        if(state.sensorTimestamp === null){
            return true
        }
        else {
            const removeBefore = state.sensorTimestamp - (8*60*60) - (15*60) // keep only 8h -- 15min threshold
            // const entry_date =  entry.Date.substring(5,7) + "/" + entry.Date.substring(8,10) + "/" + entry.Date.substring(0,4) + entry.Date.substring(10)

            return Math.round(new Date(entry.Date_Chart).getTime()*1000) >= Math.round(new Date(removeBefore).getTime()*1000)
        } 
    }

    const sameSensor = (i) => {
        return (element) => {
            return element.SN === action.data.sensor_data[i].SN && element.Date === action.data.sensor_data[i].Date;
        }
    }

    const updateData = () => {

        if(state.sensorDataInterval !== undefined && state.sensorDataInterval !== null && state.sensorDataInterval.length > 0){
            var sensorDataInterval = state.sensorDataInterval.filter(removeOld)

            if(action.data.sensor_data !== undefined && action.data.sensor_data  !== null && action.data.sensor_data.length > 0){

                for(var i=0; i < action.data.sensor_data.length; i++ ){

                    const already_exist = sensorDataInterval.find(sameSensor(i))

                    if( already_exist === undefined ){
                        sensorDataInterval.push(action.data.sensor_data[i])
                    }
                }
            }

            var sensorChartMax = 0

            sensorDataInterval.forEach((sensor) => {
                if(sensor.TSP > sensorChartMax){
                    sensorChartMax = sensor.TSP
                }
            })

            sensorChartMax += 10;

            return {sensorDataInterval,sensorChartMax}

        }
        else{
            return {sensorDataInterval: state.sensorDataInterval,sensorChartMax: state.sensorChartMax}
        }

    }

    switch(action.type){
        
        case 'ADD_DATA_NOW':
            return {
                ...state,
                sensorDataNow: action.data.sensor_data,
                sensorOldAlarms: action.data.sensorOldAlarms,
                sensorExistAlarm: action.data.sensorExistAlarm,
                sensorState: action.data.state,
                sensorLoading: false,
            }
        case 'ADD_LOCATIONS':
            return {
                ...state,
                sensorLocations: action.data,
                sensorLoading: false,
            }
        case 'ADD_DATA_NOW_UPDATE':
            const {sensorDataInterval, sensorChartMax} = updateData()

            return {
                ...state,
                sensorChartInterval: action.data.time_interval,
                sensorDataInterval: sensorDataInterval,
                sensorChartMax: sensorChartMax,
                sensorState: action.data.state,
                sensorLoading: false,
            }
        case 'ADD_DATA_INTERVAL':
            return {
                ...state,
                sensorDataInterval: action.data.sensors,
                sensorChartInterval: action.data.time_interval,
                sensorChartMax: action.data.max,
                sensorLoading: false,
            }

        case 'SET_TIMESTAMP':
            return {
                ...state,
                sensorTimestamp: action.timestamp,
                sensorCloudTimestamp: action.cloudTimestamp,
                sensorNextUpdate: action.nextTimestamp,
                sensorLoading: false,
            }
        case 'ADD_DATA_ERROR':
            return {
                ...state,
                sensorError: (action.err.response !== undefined && action.err.response.data !== undefined ? action.err.response.data : "Internal Error"),
                sensorDataNow: null,
                sensorDataInterval: null,
                sensorState: null,
                sensorLoading: false,

            }
        case 'RESET_SENSOR_DATA':
            return {
                sensorLoading: false,
                sensorError: null,
                sensorState: null,
                sensorDataNow: null,
                sensorDataInterval: null,
                sensorTimestamp:  null, // change this to now
                sensorCloudTimestamp: null,
                sensorNextUpdate: null, // timestamp of next update
                sensorDisplayChoosed: 1, // 1x speed
                sensorDisplaySpeed: { 
                    0: { label: "Pause", speed:0 }, 
                    1: { label: "1s", speed:1 }, 
                    2: { label: "15m", speed:15*60 }
                },
                sensorUpdateInterval: 1 * 60, // update interval in seconds
                sensorUpdateIntervalPlayback: 15 * 60, // update interval in seconds for playbak
                sensorChartInterval: null,
                sensorLocations: null,
                sensorChartMax: 0,
                sensorFromAlarm: false, // came from alarm page
                sensorOldAlarms: [],
                sensorExistAlarm: false
            }
        case 'SENSOR_LOADING':
            return {
                ...state,
                sensorLoading: true,
            }

        case 'SET_DISPLAY_SPEED':
            return {
                ...state,
                sensorDisplayChoosed: action.sensorDisplayChoosed,
            }
        case 'SET_FROM_ALARM':
            return {
                ...state,
                sensorFromAlarm: action.sensorFromAlarm,
            }
        case 'SET_CHART_SCALE':
            return {
                ...state,
                sensorLoading: false,
            }
        case 'CLOSE_OLD_ALARM':
            return {
                ...state,
                sensorOldAlarms: action.old_alarms,
            }
        case 'RESET_OLD_ALARM':
            return {
                ...state,
                sensorOldAlarms: [],
            }
        default: 
            return state;
    }
    
}

export default sensorReducer