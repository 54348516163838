import React, { Component } from 'react';
import { connect } from 'react-redux';
import './WindCompass.css'
import Windrose from 'windrose';
import cloud_rain from '../../images/cloud_rain.png'
import { Config } from '../../configs/config';

class WindCompass extends Component {

	static defaultProps = {
		direction: 0,
		directionNames: ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
	};

	getRainIndicator = (weatherData) => {
		if (('RainStatus' in weatherData) && weatherData.RainStatus === 'Y') {
			return (
				<div className="rain_indicator text-center">
					<img src={cloud_rain} alt="Rainning" title="Rainning" />
				</div>
			)
		}

		return null
	}

	getWindData = () => {

		const { weatherData, sensorCloudTimestamp } = this.props

		if (weatherData && sensorCloudTimestamp) {

			const now_minus_15 = (sensorCloudTimestamp - (15 * 60))

			// Check if data exists and is in the last 15 min
			if (now_minus_15 < weatherData.DateTime) {
				const direction = Windrose.getPoint(weatherData.AvgWD, { depth: 2 }).symbol

				return (
					<div>
						<div className="compass mx-auto">
							<div className="compass__windrose">
								{[...Array(10)].map((k, i) => <div className="compass__mark" key={i + 1}></div>)}
								<div className="compass__mark--direction-h"></div>
								<div className="compass__mark--direction-v"></div>
								<div className="compass_winddirection" style={{ transform: `rotate(${weatherData.AvgWD}deg)` }}></div>

							</div>
							<div className="compass__arrow-container">
								<div className="compass__arrow" style={{ transform: `rotate(${weatherData.AvgWD + 45}deg)` }}></div>
								<div className="compass__labels">
									<span>{direction}</span>
									<span>{weatherData.AvgWD}<sup>o</sup></span>
								</div>
							</div>
						</div>
						<div className="h5 pt-3 text-center">
							Avg Wind Speed: {weatherData.AvgWS / 10} m/s
						</div>
						{weatherData.UserInfo && !weatherData.UserInfo.toLowerCase().includes(Config.client) ? 
                        	<p className='text-danger text-center mb-0'> Using fallback meteostation</p> : null
                    	}
						{this.getRainIndicator(weatherData)}
					</div>
				)
			}
		}

		return (
			<div>
				<div className="compass mx-auto">
					<div className="compass__windrose">
						{[...Array(10)].map((k, i) => <div className="compass__mark" key={i + 1}></div>)}
						<div className="compass__mark--direction-h"></div>
						<div className="compass__mark--direction-v"></div>
					</div>
					<div className="compass__arrow-container">
					</div>
				</div>
				<div className="h5 pt-3 text-center">
					Waiting for data
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className="windcompass p-3 rounded ">
				{this.getWindData()}
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		sensorCloudTimestamp: state.sensor.sensorCloudTimestamp,
		weatherData: state.weather.weatherData,
		weatherError: state.weather.weatherError
	}
}

export default connect(mapStateToProps, null)(WindCompass);