import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; 
import { connect } from 'react-redux';
import { getUncheckAlarms } from '../../store/actions/alarmActions';


class CheckAlarm extends Component {

    state = {
        user_id: null,
        id: null,
        comment: '',
        loading: false
    }


    componentDidMount() {
        this.setState({
            id: this.props.match.params.id
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showMessage() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h5> Alarm checked successfully </h5>
						<button className='btn btn-info mt-2 mx-1' onClick={ onClose }> Ok </button>
					</div>
				)
			}
		})
    };
    
    showErrorMessage(message) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h5> {message} </h5>
						<button className='btn btn-info mt-2 mx-1' onClick={ onClose }> Ok </button>
					</div>
				)
			}
		})
	};

    handleSubmit = event => {

        event.preventDefault();

        if(this.state.comment=== ''){
            this.showErrorMessage('You must enter a comment to check this alarm')
            return
        }

        this.setState({ loading: true })
        var data = [];

        data['comment'] = this.state.comment;

        if (this.state.id !== null) {
            data['id'] = this.state.id
        }
        
        data['user_id'] = this.props.user.id
        
        axios.post('api/alarms/comments', { ...data })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                })
                this.props.getUncheckAlarms();
                this.props.history.push("/alarms")
            })
            .catch(err => {
                    this.setState({ loading: false })
                    this.showErrorMessage(err.response.data.error)
                    this.props.getUncheckAlarms();
                    return   
            })
    }

    render() {
        return (
            <div className="row">
                <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 my-5 shadow px-5 py-4 bg-white rounded">
                    <h2 className="text-center pb-3">Check Alarm</h2>
                    <form onSubmit={this.handleSubmit}>
                     <div className="form-group row">
                        <div className="col-12 col-md-3 font-weight-bold">
                            <label htmlFor="exampleFormControlInput1">Alarm Comment: </label>
                        </div>
                        <div className="col-12 col-md-9">
                            <textarea rows="4" name="comment" className="form-control" id="comment" onChange={this.handleChange} value={this.props.values} disabled={ this.props.disabled }></textarea>
                        </div>   
                    </div>
                        <div className="form-group row">
                            <div className="col-12 text-right">
                                <button type="submit" className="btn btn-info ">
                                    {this.state.loading === true ? <FontAwesomeIcon icon="spinner" spin /> : "Check Alarm"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
	return {
		user: state.auth.user
	}
}
const mapDispatchToProps = (dispatch) => {
    return {
        getUncheckAlarms : () => dispatch( getUncheckAlarms() )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CheckAlarm);
