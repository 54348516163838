import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSensorDataInterval, getSensorDataNow, setTimestamp, setDisplaySpeed, resetSensorData, setFromAlarm } from '../../store/actions/sensorActions';
import { getWeatherData } from '../../store/actions/weatherActions';
import { getUncheckAlarms } from '../../store/actions/alarmActions';

import '../../styles/react-confirm-alert.css';

class AlarmList extends Component {
	state = {
		alarms: [],
		currentPage: 1,
		loading: false,
		interval: null
	}

	componentDidMount() {
		this.setState({ loading: true })
		this.props.getUncheckAlarms();
		axios.get('api/alarms')
			.then(res => {
				const alarms = res.data.data;				
				const page = res.data;
				this.setState({ alarms, page, loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
			this.setState({
				interval: setInterval(() => this.refreshData(), 60000),
			})
	}

	componentWillUnmount() {
        clearInterval(this.state.interval);
    }

	refreshData = () => {
		this.props.getUncheckAlarms();

		// if page different from 1st don't refresh
		if(this.state.page.current_page === 1){
			axios.get('api/alarms')
			.then(res => {
				const alarms = res.data.data;				
				const page = res.data;
				this.setState({ alarms, page, loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
				return
			}
		}

	changePage(page) {
		this.setState({ currentPage: page, loading: true })
		axios.get('api/alarms?page=' + page)
			.then(res => {
				const alarms = res.data.data;
				const page = res.data;
				this.setState({ alarms, page, loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
	}

	goToHistory(alarms){
		this.props.resetSensorData()

        const interval = {
            timestamp: moment(alarms.sensor.Date).unix(), // cloud timestamp 
            timestamp_from: moment(alarms.sensor.Date).subtract(4, 'hours').unix(), // minus 4h
            timestamp_to: moment(alarms.sensor.Date).add(4, 'hours').unix(), // plus 4h
        }

        this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp, nextTimestamp: interval.timestamp + this.props.sensorUpdateInterval })

        this.props.setDisplaySpeed({ sensorDisplayChoosed: 0 }) // choose 15min

        this.props.getSensorDataInterval(interval)
        this.props.getSensorDataNow({ timestamp: interval.timestamp })
		this.props.getWeatherData({ timestamp: interval.timestamp })    
		
		this.props.setFromAlarm(true);

		this.props.history.push("/")
	}

	line() {
		if (this.state.loading === true) {
			return (
				<tr><td colSpan={6} className="text-center h1" ><FontAwesomeIcon icon="spinner" spin /></td></tr>
			)

		}
		else {

			return (
				this.state.alarms.map(alarms => {
					return (<tr key={alarms.id}>
						<td>{alarms.sensor.LOC}</td>
						<td>{alarms.sensor.Date}</td>
						<td>{alarms.sensor.TSP.toFixed(2)}</td>
						<td>{alarms.sensor.PM10.toFixed(2)}</td>
						<td>{alarms.sensor.PM25.toFixed(2)}</td>
						<td>{alarms.sensor.PM1.toFixed(2)}</td>
						<td>{(alarms.sensor.PM10 - alarms.sensor.PM25).toFixed(2)}</td>
						<td>{alarms.sensor.alarm_pm_trigger.toFixed(2)}</td>
						<td>{alarms.sensor.AvgWD}</td>
						<td>{(alarms.sensor.AvgWS / 10).toFixed(2)}</td>
						<td>{alarms.alarm_checked===1 ? 'Yes' : 'No'}</td>
						<td>{alarms.date_checked ? alarms.date_checked : null}</td> 
						<td>{alarms.user ? alarms.user.name : null}</td>
						<td>{alarms.comments ? alarms.comments : null}</td>
					<td>{alarms.user_comment? alarms.user_comment.name : null} &nbsp;
					{alarms.user_comment? alarms.updated_at : null}</td>
						<td>
							<button className="btn btn-info mt-2" type="button" onClick={ () => this.goToHistory(alarms)}> Show on Map </button>
							{alarms.comments ? null	:<button className="btn btn-info mt-2" type="button" onClick={ () => this.props.history.push("/alarms/check/"+alarms.id)}> Check </button> }
							{alarms.comments ? 	<button className="btn btn-info mt-2" type="button" onClick={ () => this.props.history.push("/alarms/edit/"+alarms.id)}> Edit </button> : null}
						</td>
					</tr>)
				}
				)
			)
		}
	}

	pagination() {
		let table = []

		for (let i = this.state.page.current_page - 3; i <= this.state.page.current_page + 3; i++) {
			if (this.state.page.current_page === i) {
				table.push(
					<li key={i} className="page-item active"><span className="page-link" onClick={() => this.changePage(i)} >{i}</span></li>
				)
			}
			else if (i >= 1 && i <= this.state.page.last_page) {
				table.push(
					<li key={i} className="page-item"><span className="page-link" onClick={() => this.changePage(i)} >{i}</span></li>
				)
			}
		}

		return (
			<ul className="pagination">
				{(this.state.page.prev_page_url === null) ? <div></div> : <li className="page-item"><span className="page-link" onClick={() => this.changePage(this.state.page.current_page - 1)}>Previous</span></li>}
				{table}
				{(this.state.page.next_page_url === null) ? <div></div> : <li className="page-item"><span className="page-link" onClick={() => this.changePage(this.state.page.current_page + 1)}>Next</span></li>}
			</ul>
		)

	}

	alarmSettingsButton(){
		if (this.props.user.role.name === 'SuperAdmin'){
			return (
				<button className="btn btn-info mb-3 mr-3" type="button" onClick={ () => this.props.history.push("/admin/alarms") }> Alarm Settings </button>
			)
		}
		return null
	}


	render() {
		return (
			<div className="container-fluid pt-2">

				<div className="row">
					<div className="col-12 d-flex flex-row flex-wrap justify-content-between">
						<nav className="ml-3" aria-label="Page navigation">
							{this.state.page !== undefined ? this.pagination() : null}
						</nav>
						{ this.alarmSettingsButton() }
					</div>
				</div>
				<div className="col-12">
					<table className="table table-hover bg-light">
						<thead>
							<tr>
								<th scope="col" style={{width:'180px'}}>Sensor Name</th>
								<th scope="col" style={{width:'107px'}}>Sensor Date</th>
								<th scope="col" style={{width:'80px'}}>TSP</th>
								<th scope="col" style={{width:'80px'}}>PM 10</th>
								<th scope="col" style={{width:'70px'}}>PM 2.5</th>
								<th scope="col" style={{width:'70px'}}>PM1</th>
								<th scope="col" style={{width:'70px'}}>PM10-PM2.5</th>
								<th scope="col" style={{width:'87px'}}>Alarm threshold</th>
								<th scope="col" style={{width:'70px'}}>AvgWD</th>
								<th scope="col" style={{width:'70px'}}>AvgWS</th>
								<th scope="col" style={{width:'92px'}}>Checked</th>
								<th scope="col" style={{width:'107px'}}>Date Checked</th>
								<th scope="col" style={{width:'150px'}}>User that Checked</th>
								<th scope="col">Comment</th>
								<th scope="col" style={{width:'150px'}}>Last User that commented</th>
								<th scope="col" className="text-center" style={{width:'150px'}}>Options</th>
							</tr>
						</thead>
						<tbody>
							{this.line()}
						</tbody>
					</table>

				</div>


			</div>
		)
	}
}

const mapStateToProps = (state) => {
    return {
		user: state.auth.user,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval : (params) => dispatch( getSensorDataInterval(params) ),
        getSensorDataNow : (params) => dispatch( getSensorDataNow(params) ),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        setDisplaySpeed : (params) => dispatch( setDisplaySpeed(params) ),
        resetSensorData: () => dispatch( resetSensorData() ),
        getWeatherData: (params) => dispatch(getWeatherData(params)),
		getUncheckAlarms : () => dispatch( getUncheckAlarms() ),
		setFromAlarm : (params) => dispatch( setFromAlarm(params) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmList);