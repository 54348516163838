import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Config } from '../../configs/config';

class AdminFallbackettings extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
        }
    }

    componentDidMount(){
        this.setState({ loading: true })
		axios.get(`api/weather/fallback/${Config.client}`)
			.then(res => {
				const {using_fallback} = res.data;
				this.setState({ "Meteostation": using_fallback === 1,loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
    }

    showMessage() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='shadow rounded p-5 bg-light text-center'>
                        <h5> Fallback settings changed successfully </h5>
                        <button className='btn btn-info mt-2 mx-1' onClick={() => {
                            onClose()
                        }}> Ok </button>
                    </div>
                )
            }
        })
    };

    toggleFallback = (sensorName, value) => {
        axios.post(`api/weather/fallback/${Config.client}`, {"fallback": value})
        .then(res => {
            if(res.data === "ok"){
                this.showMessage()
            }
            this.setState({loading: false });
        })
        .catch(err => {
            this.setState({ loading: false })
        })

        this.setState({
            [sensorName]: value
        })
    }

    getClassName = (sensorName, value) => {
        if(this.state[sensorName] === value){
            return "btn btn-primary disabled"
        } 

        return "btn btn-secondary"
    }

    isDisabled = (sensorName, value) => {
        if(this.state[sensorName] === value){
            return true
        } 

        return false
    }

    render() {
        return (
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3 my-5 shadow px-5 py-4 bg-white rounded">
                <div className="col-12 text-center">
                    <h2> Fallback Settings</h2>
                </div>
                <div className="col-12 my-3">
                    Enable/Disable fallback sensors
                </div>
                <div className="col-12">
                    <table className='table'>
                        <tbody className="w-100">
                            <tr>
                                <td>
                                    Meteostation
                                </td>
                                <td className='text-right'> 
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className={this.getClassName("Meteostation", false)} onClick = {() => this.toggleFallback("Meteostation", false)} disabled={this.isDisabled("Meteostation", false) || this.state.loading} >Main Sensor</button>
                                        <button type="button" className={this.getClassName("Meteostation", true)} onClick = {() => this.toggleFallback("Meteostation", true)} disabled={this.isDisabled("Meteostation", true) || this.state.loading}>Fallback Sensor</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(AdminFallbackettings);
