import axios from 'axios';

// process get params like post
const prepareGetUrl = (url, params) => {
    var getUrl = url;

    for (var k in params) {
        if (params.hasOwnProperty(k) && params[k] !== undefined) {
            getUrl += "/" + params[k];
        }
    }

    return getUrl
}


// get current weather data
export const getWeatherData = ({ timestamp }) => {

    return dispatch => {
        dispatch({ type: 'WEATHER_LOADING' });

        axios.get(prepareGetUrl('api/weather/' + timestamp))
            .then(res => {

                // check if the value is from the meteo station or old meteo data
                if ('RainInt' in res.data) {
                    
                    res.data.AvgWS = res.data.AvgWS * 10 // standard format for Wind Speed divided after by 10

                    dispatch({ type: 'ADD_WEATHER_DATA', data: res.data });
                }
                else {
                    res.data.DateTime = Math.round(new Date(res.data.Date).getTime()/1000)
                    dispatch({ type: 'ADD_WEATHER_DATA', data: res.data });
                }
            })
            .catch(err => {
                dispatch({ type: 'ADD_WEATHER_DATA_ERROR', err });
        });
    };
};
