import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { logout } from '../../store/actions/authActions';


class Timeout extends Component {

    constructor(){
		super();

		this.state = {
			showTimoutPopup: false
		}

		this.idleTimer = null
		this.onIdle = this._onIdle.bind(this)
		this.checkInterval = this.checkInterval.bind(this)
    }
    
    componentDidMount() {
		this.interval = setInterval(() => this.checkInterval(), 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
    }
    
    checkInterval(){

		if( this.idleTimer && this.idleTimer.getRemainingTime() < 1000 * 15 && this.props.user !== undefined && this.props.user!==null  && (this.props.user.role_id === 1 || this.props.user.role_id === 2 )){
			this.setState({ showTimoutPopup: true })
		}
		else {
			this.setState({ showTimoutPopup: false })
		}
	}

	_onIdle(e) {
		if(this.props.user !== undefined && this.props.user!==null && (this.props.user.role_id === 1 || this.props.user.role_id === 2 )){
			this.props.logout();
		}
	}

	timeToLogout(){
		return (
			<div className="logout-timeout bg-danger text-white py-2 px-3 text-center">
				User will logout in <b> { Math.ceil(this.idleTimer.getRemainingTime() / 1000) } seconds </b>, due to inactivity
			</div>
		)
	}

    render() {
        return (
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={ 1000 }
                    timeout={ 1000 * 60 * 10 } />

                { this.state.showTimoutPopup ? this.timeToLogout() : null }
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch( logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timeout);