import axios from 'axios';

export const login = ({ email, password }) => {
    return dispatch => {
        dispatch({ type: 'LOGIN_STARTED' });

        axios.post('api/auth/login', { email, password })
            .then(res => {
                // if role is user+ and user save 
                if(res.data.user.role_id !== 1 && res.data.user.role_id !==2) {
                    localStorage.setItem("remember", JSON.stringify(true));
                    localStorage.setItem("tokens", JSON.stringify(res.data.tokens));
                    localStorage.setItem("user", JSON.stringify(res.data.user));
                }
                else {
                    sessionStorage.setItem("tokens", JSON.stringify(res.data.tokens));
                    sessionStorage.setItem("user", JSON.stringify(res.data.user));
                }

                dispatch({ type: 'LOGIN_SUCCESS', data: res.data});
            })
            .catch(err => {
                dispatch({ type: 'LOGIN_ERROR', err });
            }
        );  
    };
};

export const logout = () => {

    localStorage.removeItem('remember');
    localStorage.removeItem('tokens');
    sessionStorage.removeItem('tokens');
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    localStorage.removeItem('alarms');
    return dispatch => {
        dispatch({ type: 'LOGOUT' });
    };

};

export const refreshToken = (res) => {

    if(localStorage.getItem('remember')){
        localStorage.setItem("tokens", JSON.stringify(res.data));
    }
    else {
        sessionStorage.setItem("tokens", JSON.stringify(res.data));
    }

    return dispatch => {
        dispatch({ type: 'REFRESH_TOKEN', data: res.data});
    };

};