import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import GenericForm from '../forms_helper/GenericForm';
import { getSensorLocations, saveChartScale } from '../../store/actions/sensorActions'

class AdminGraphScale extends Component {
    constructor(){
        super()

        this.state = { 
            chart_scale: 90,
            chart_scale_loaded: false
        }
    }

    componentDidUpdate(){
        this.props.getSensorLocations(Math.round(new Date().getTime()/1000))
    }

    handleChange = e => {

        if(e.target.value.length === 0 ){
            this.setState({
                [e.target.name]: ''
            })
        }
        else if(!isNaN(Number(e.target.value))){
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    showMessage() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h5> Graph scale changed successfully </h5>
						<button className='btn btn-info mt-2 mx-1' onClick={ () => {
                            this.props.history.goBack()
                            onClose()
						}}> Ok </button>
					</div>
				)
			}
		})
    };
    
    saveScale(){
        let {chart_scale} = this.state

        // some function
        this.props.saveChartScale(chart_scale)
        this.showMessage()
    }

    drawScaleInputs(){
        if(this.props.sensorLocations){
           
            if(this.state.chart_scale_loaded === false){
                let valid_scale = this.props.sensorLocations.filter( location => location.chart_scale > 0)
            
                if(valid_scale.length > 0 && valid_scale[0].chart_scale && this.state.chart_scale !== valid_scale[0].chart_scale){
                    this.setState({chart_scale_loaded: true, chart_scale: valid_scale[0].chart_scale})
                }
                else {
                    this.setState({chart_scale_loaded: true})
                }
            }
            

            return (
                <GenericForm
                    classNameLabel="col-12 col-md-6 text-right font-weight-bold"
                    classNameInput="col-12 col-md-3"
                    labels="Max y scale"
                    name="chart_scale"
                    type="number"
                    values={ this.state.chart_scale || 90 }
                    onChange={this.handleChange || ''}
                    errorMessage={this.state.error || ''}
                    disabled={false}
                />
            ) 
        }
        else {
            return (
                <p> Loading locations...</p>
            )
        }
       
    }
    
    render() {
        return(
            <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 my-5 shadow px-5 py-4 bg-white rounded">
                <div className="col-12 text-center">
                    <h2> Graph scale</h2>
                </div>
                <div className="col-12 my-3 text-center">
                   Configure the maximum value for the y scale 
                </div>
                <div className="col-12">
                    { this.drawScaleInputs() }
                </div>
                <div className="col-12 text-center">
                    <button className="btn btn-primary" type="button" onClick={() => this.saveScale()}> Save changes </button>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
	return {
		sensorLocations: state.sensor.sensorLocations,
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveChartScale: (params) => dispatch(saveChartScale(params)),
        getSensorLocations : (params) => dispatch( getSensorLocations(params) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminGraphScale);
