import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import '../../styles/react-confirm-alert.css';

class AdminUser extends Component {
	state = {
		users: [],
		currentPage: 1,
		loading: false
	}

	componentDidMount() {
		this.setState({ loading: true })
		axios.get('api/users')
			.then(res => {
				const users = res.data.data;
				const page = res.data;
				this.setState({ users, page, loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
	}

	changePage(page) {
		this.setState({ currentPage: page, loading: true })
		axios.get('api/users?page=' + page)
			.then(res => {
				const users = res.data.data;
				const page = res.data;
				this.setState({ users, page, loading: false });
			})
			.catch(err => {
				this.setState({ loading: false })
			})
	}

	changePassword(user) {
		this.props.history.push('/admin/changepassword/' + user.id)
	}

	submit(user) {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h2>Confirm to delete user</h2>
						<p>Are you sure you want to delete {user.name}?</p>
						<button className='btn btn-info m-1' onClick={onClose}>Cancel</button>
						<button className='btn btn-danger m-1 ' onClick={() => {
							this.deleteUser(user.id)
							onClose()
						}}>Delete</button>
					</div>
				)
			}
		})
	};

	editUser(user) {
		this.props.history.push('admin/user/' + user.id + '/edit')
	}

	deleteUser(idUser) {
		this.setState({ loading: true })
		axios.post('api/users/delete', { id: idUser })
			.then(res => {
				this.changePage(this.currentPage);
			})
			.catch(err => {
				this.setState({ loading: false })
			})

	}

	line() {
		let userRole = '';

		if (this.state.loading === true) {
			return (
				<tr><td colSpan={6} className="text-center h1" ><FontAwesomeIcon icon="spinner" spin /></td></tr>
			)

		}
		else {

			return (
				this.state.users.map(users => {
					if (users.role === null) {
						userRole = " - ";
					}
					else {
						userRole = users.role.name;
					}

					return (<tr key={users.id}>
						<td>{users.id}</td>
						<td>{users.name}</td>
						<td>{users.email}</td>
						<td>{userRole}</td>
						<td>{users.created_at}</td>
						<td>{users.updated_at}</td>
						<td>
							<FontAwesomeIcon icon="pencil-alt" className="edit-icon mx-1" onClick={() => this.editUser(users)} title="Edit user" />
							<FontAwesomeIcon icon="key" className="change-icon mx-1" onClick={() => this.changePassword(users)} title="Change password" />
							<FontAwesomeIcon icon="trash-alt" className="delete-icon mx-1" onClick={() => this.submit(users)} title="Delete user" />
						</td>
					</tr>)
				}
				)
			)
		}
	}

	pagination() {
		let table = []

		for (let i = this.state.page.current_page - 3; i <= this.state.page.current_page + 3; i++) {
			if (this.state.page.current_page === i) {
				table.push(
					<li key={i} className="page-item active"><span className="page-link" onClick={() => this.changePage(i)} >{i}</span></li>
				)
			}
			else if (i >= 1 && i <= this.state.page.last_page) {
				table.push(
					<li key={i} className="page-item"><span className="page-link" onClick={() => this.changePage(i)} >{i}</span></li>
				)
			}
		}

		return (
			<ul className="pagination">
				{(this.state.page.prev_page_url === null) ? <div></div> : <li className="page-item"><span className="page-link" onClick={() => this.changePage(this.state.page.current_page - 1)}>Previous</span></li>}
				{table}
				{(this.state.page.next_page_url === null) ? <div></div> : <li className="page-item"><span className="page-link" onClick={() => this.changePage(this.state.page.current_page + 1)}>Next</span></li>}
			</ul>
		)

	}

	adminSettingsButton() {
		let buttonsList = []
		if (this.props.user.role.name === 'SuperAdmin') {
			buttonsList.push(
				<>
					<button className="btn btn-info  mr-3" type="button" onClick={() => this.props.history.push("/admin/fallback")}> Fallback Settings </button>
				</>
			)
		}

		if (this.props.user.role.name === 'SuperAdmin' || this.props.user.role.name === 'Admin') {
		buttonsList.push(
				<>
					<button className="btn btn-info  mr-3" type="button" onClick={() => this.props.history.push("/admin/alarms")}> Alarm Settings </button>
					<button className="btn btn-info  mr-3" type="button" onClick={() => this.props.history.push("/admin/graph-scale")}> Set Graph Scale</button>
				</>
			)
		}
		return buttonsList
	}

	render() {
		return (
			<div className="container-fluid pt-2">

				<div className="row">
					<div className="col-6">
						<nav className="ml-3" aria-label="Page navigation">
							{this.state.page !== undefined ? this.pagination() : null}
						</nav>
					</div>
					<div className="col-6 pb-2 text-right" >
						{this.adminSettingsButton()}
						<Link className="btn btn-info mr-3" to="/admin/adduser">Add New User</Link>
					</div>
				</div>
				<div className="col-12">
					<table className="table table-hover bg-light">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">Name</th>
								<th scope="col">Email</th>
								<th scope="col">Role</th>
								<th scope="col">Created at</th>
								<th scope="col">Updated at</th>
								<th scope="col">-</th>
							</tr>
						</thead>
						<tbody>
							{this.line()}
						</tbody>
					</table>

				</div>


			</div>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	}
}



export default connect(mapStateToProps, null)(AdminUser);