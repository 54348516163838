import { combineReducers } from 'redux';

import authReducer from './authReducer';
import sensorReducer from './sensorReducer';
import weatherReducer from './weatherReducer';
import mapReducer from './mapReducer';
import timeControllReducer from './timeControllReducer';
import alarmReducer from './alarmReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    sensor: sensorReducer,
    weather: weatherReducer,
    map: mapReducer,
    timeControll: timeControllReducer,
    alarm: alarmReducer
})

export default rootReducer;