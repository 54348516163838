import React, { Component } from 'react';
import { connect } from 'react-redux';

import { setMapData } from '../../store/actions/mapActions';
import { Config } from '../../configs/config'


class MapDust extends Component {

    constructor() {
        super()
        this.canvasRef = React.createRef();

        // load map background
        this.background = new Image();

        const { client } = Config
        import(`../../images/${client}_map.jpeg`).then(map => {
            this.background.src = map.default;
        });

        this.renderCanvas = this.renderCanvas.bind(this);
        this.drawShadowOverClient = this.drawShadowOverClient.bind(this);
        this.drawCloud = this.drawCloud.bind(this);

        window.addEventListener("resize", this.renderCanvas);
    }

    drawShadowOverClient(width_ratio, height_ratio, centerShift_x, centerShift_y) {
        const { client } = Config

        this.ctx.save()

        switch (client) {
            case "emo": {
                this.ctx.translate((width_ratio / 3.30) + centerShift_x, (height_ratio / 1.8) + centerShift_y);
                this.ctx.fillStyle = 'rgba(231, 76, 60, 0.6)';

                this.ctx.beginPath();
                this.ctx.moveTo(0, 0);
                this.ctx.lineTo(width_ratio / 55, height_ratio / 11.5);
                this.ctx.lineTo(width_ratio / 8.2, height_ratio / 55);
                this.ctx.lineTo(width_ratio / 7.2, -height_ratio / 35);
                this.ctx.lineTo(width_ratio / 7.2, -height_ratio / 26);
                this.ctx.closePath();
                break;
            }
            case "eecv": {
                this.ctx.translate((width_ratio / 2.22) + centerShift_x, (height_ratio / 2.6) + centerShift_y);
                this.ctx.fillStyle = 'rgba(231, 76, 60, 0.0)';
                this.ctx.lineWidth = 5;
                // set line color
                this.ctx.strokeStyle = '#f9ca24';

                this.ctx.beginPath();
                this.ctx.moveTo(0, 0);
                this.ctx.lineTo(-width_ratio / 200, height_ratio / 13);
                this.ctx.lineTo(-width_ratio / 25.5, height_ratio / 4.5);
                this.ctx.lineTo(width_ratio / 21, height_ratio / 3.4);
                this.ctx.lineTo(width_ratio / 21, height_ratio / 3.8);
                this.ctx.lineTo(width_ratio / 22, height_ratio / 4);
                this.ctx.lineTo(width_ratio / 18, height_ratio / 3.95);
                this.ctx.lineTo(width_ratio / 18, height_ratio / 4.5);
                this.ctx.lineTo(width_ratio / 21, height_ratio / 5.1);
                this.ctx.lineTo(-width_ratio / 90, height_ratio / 6.8);
                this.ctx.lineTo(width_ratio / 200, height_ratio / 12);
                this.ctx.lineTo(width_ratio / 14, height_ratio / 7.2);
                this.ctx.lineTo(width_ratio / 11.5, height_ratio / 13.5);
                this.ctx.closePath();
                this.ctx.stroke();

                break
            }
            default:
        }

        this.ctx.fill();
        this.ctx.restore()
    }

    drawDustMonitor(width_ratio, height_ratio, centerShift_x, centerShift_y, square_line_x, square_line_y, line_x2, line_y2, debug) {
        let color = debug ? 'blue' : 'red'

        this.ctx.save()

        this.ctx.translate((width_ratio / square_line_x) + centerShift_x, (height_ratio / square_line_y) + centerShift_y);
        this.ctx.fillStyle = color
        this.ctx.beginPath()
        this.ctx.rect(0, 0, width_ratio / 180, height_ratio / 95)
        this.ctx.fill()
        this.ctx.closePath();

        this.ctx.restore()

        // draw line to graph
        this.ctx.save()
        this.ctx.lineWidth = 2;
        this.ctx.strokeStyle = color;
        this.ctx.moveTo((width_ratio / square_line_x) + centerShift_x, (height_ratio / square_line_y) + centerShift_y);
        this.ctx.lineTo((width_ratio / line_x2) + centerShift_x, (height_ratio / line_y2) + centerShift_y);
        this.ctx.stroke();
        this.ctx.restore()
    }

    drawMarks(width_ratio, height_ratio, centerShift_x, centerShift_y) {

        const locations = this.props.sensorLocations ? this.props.sensorLocations : [];

        const { client } = Config

        this.ctx.save()

        switch (client) {
            case "emo": {
                // EMO CENTER 
                // this.ctx.save()

                // this.ctx.translate((width_ratio/2.7) + centerShift_x , (height_ratio/1.76) + centerShift_y);
                // this.ctx.fillStyle= 'black'
                // this.ctx.beginPath()
                // this.ctx.rect(0, 0, width_ratio/180, height_ratio/95)
                // this.ctx.fill()
                // this.ctx.closePath();

                // this.ctx.restore()
                break;
            }
            default:
        }

        // drawing red squares and lines to sensors locations
        for (let i = 0; i < locations.length; i++) {
            const { square_line1_x, square_line1_y, line2_x, line2_y } = locations[i].coordinates
            this.drawDustMonitor(width_ratio, height_ratio, centerShift_x, centerShift_y, square_line1_x, square_line1_y, line2_x, line2_y)
        }

    }

    drawCloud(width_ratio, height_ratio, centerShift_x, centerShift_y, scale_x, scale_y) {

        const { weatherData } = this.props

        if (weatherData && weatherData.AvgWS >= 20) {

            const { client } = Config

            this.ctx.save()

            switch (client) {
                case "emo": {
                    this.ctx.translate((width_ratio / 2.7) + centerShift_x, (height_ratio / 1.76) + centerShift_y);
                    break;
                }
                case "eecv" : {
                    this.ctx.translate((width_ratio / 2.08) + centerShift_x, (height_ratio / 1.88) + centerShift_y);
                    break;
                }
                case "hvc" : {
                    this.ctx.translate((width_ratio / 2.08) + centerShift_x, (height_ratio / 1.88) + centerShift_y);
                    break;
                }
                default:
            }

            this.ctx.fillStyle = this.props.sensorExistAlarm ? 'rgba(255, 0, 0, 0.5)' : 'rgba(241, 196, 15, 0.5)';

            const cloud_dir = (weatherData.AvgWD + 180) % 360;

            let cloud_size = {}

            if (weatherData.AvgWS <= 100) {
                cloud_size = {
                    width: 92.658 - 0.127 * weatherData.AvgWS,
                    height: 110.1 + 1.899 * weatherData.AvgWS,
                    ctrl_width: 92.658 - 0.127 * weatherData.AvgWS,
                    ctrl_height: 43.04 + 0.5696 * weatherData.AvgWS
                }
            }
            else {
                cloud_size = {
                    width: 90 - 0.1 * weatherData.AvgWS,
                    height: -450 + 7.500 * weatherData.AvgWS,
                    ctrl_width: 90 - 0.1 * weatherData.AvgWS,
                    ctrl_height: -200 + 3 * weatherData.AvgWS
                }
            }

            this.ctx.rotate(cloud_dir * Math.PI / 180);
            this.ctx.scale(scale_x, scale_y);

            this.ctx.beginPath();
            this.ctx.moveTo(-cloud_size.width, -cloud_size.height);
            this.ctx.bezierCurveTo(-cloud_size.ctrl_width / 3, cloud_size.ctrl_height, cloud_size.ctrl_width / 3, cloud_size.ctrl_height, cloud_size.width, -cloud_size.height)
            this.ctx.bezierCurveTo(cloud_size.ctrl_width, -cloud_size.height * 1.2, -cloud_size.ctrl_width, -cloud_size.height * 1.2, -cloud_size.width, -cloud_size.height)
            this.ctx.fill();

            this.ctx.restore()


        }
    }

    drawImageScaled = (canvas, ratio, centerShift_x, centerShift_y) => {
        this.ctx.save()

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.ctx.drawImage(this.background, 0, 0, this.background.width, this.background.height,
            centerShift_x, centerShift_y, this.background.width * ratio, this.background.height * ratio);

        this.ctx.restore()

    }

    componentDidMount() {

        this.ctx = this.canvasRef.current.getContext('2d');

        const canvas = {
            width: window.innerWidth,
            height: window.innerHeight - 54 - 56
        }

        // update size after resize
        this.canvasRef.current.width = canvas.width
        this.canvasRef.current.height = canvas.height

        // after background image load
        this.background.onload = () => {
            const hRatio = canvas.width / this.background.width;
            const vRatio = canvas.height / this.background.height;
            const ratio = Math.max(hRatio, vRatio);
            const centerShift_x = (canvas.width - this.background.width * ratio) / 2;
            const centerShift_y = (canvas.height - this.background.height * ratio) / 2;
            const width_ratio = this.background.width * ratio
            const height_ratio = this.background.height * ratio
            const scale_x = width_ratio / 1905
            const scale_y = height_ratio / 834

            this.drawImageScaled(canvas, ratio, centerShift_x, centerShift_y, this.background, this.ctx)
            this.drawShadowOverClient(width_ratio, height_ratio, centerShift_x, centerShift_y)
            this.drawMarks(width_ratio, height_ratio, centerShift_x, centerShift_y)
            this.drawCloud(width_ratio, height_ratio, centerShift_x, centerShift_y, scale_x, scale_y)

            this.props.setMapData(width_ratio, height_ratio, centerShift_x, centerShift_y)
        };

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.renderCanvas);
    }

    renderCanvas() {
        // const { weatherData, sensorLocations } = this.props

        // if(weatherData && sensorLocations){

        const canvas = {
            width: window.innerWidth,
            height: window.innerHeight - 54 - 56
        }

        const hRatio = canvas.width / this.background.width;
        const vRatio = canvas.height / this.background.height;
        const ratio = Math.max(hRatio, vRatio);
        const centerShift_x = (canvas.width - this.background.width * ratio) / 2;
        const centerShift_y = (canvas.height - this.background.height * ratio) / 2;
        const width_ratio = this.background.width * ratio
        const height_ratio = this.background.height * ratio
        const scale_x = width_ratio / 1905
        const scale_y = height_ratio / 834

        // update size after resize
        this.canvasRef.current.width = canvas.width
        this.canvasRef.current.height = canvas.height

        this.drawImageScaled(canvas, ratio, centerShift_x, centerShift_y, this.background, this.ctx)
        this.drawShadowOverClient(width_ratio, height_ratio, centerShift_x, centerShift_y)
        this.drawMarks(width_ratio, height_ratio, centerShift_x, centerShift_y)
        this.drawCloud(width_ratio, height_ratio, centerShift_x, centerShift_y, scale_x, scale_y)

        this.props.setMapData(width_ratio, height_ratio, centerShift_x, centerShift_y)
        // }
    }

    render() {

        if (this.canvasRef.current) {
            this.renderCanvas()
        }

        return (
            <canvas className="map-dust" ref={this.canvasRef} >
            </canvas>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        weatherData: state.weather.weatherData,
        weatherError: state.weather.weatherError,
        sensorExistAlarm: state.sensor.sensorExistAlarm,
        sensorLocations: state.sensor.sensorLocations
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMapData: (width_ratio, height_ratio, centerShift_x, centerShift_y) => dispatch(setMapData(width_ratio, height_ratio, centerShift_x, centerShift_y)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapDust);