import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { Service } from 'axios-middleware';

import {Config} from './configs/config'
import { refreshToken, logout } from './store/actions/authActions';

import Navbar from './components/layout/Navbar'
import Login from './components/auth/Login'
import Contacts from './components/info/Contacts'
import Info from './components/info/Info'
import AdminUsers from './components/admin/AdminUsers'
import Dashboard from './components/dashboard/Dashboard'
import AdminAddUser from './components/admin/AdminAddUser'
import ChangePassword from './components/user/ChangePassword'
import DustCharts from './components/charts/DustCharts'
import AdminEditUser from './components/admin/AdminEditUser'
import Timeout from './components/timout/Timeout'
import Export from './components/export/Export'
import AlarmList from './components/alarm/AlarmsList'
import AdminAlarmSettings from './components/admin/AdminAlarmSettings'
import AdminGraphScale from './components/admin/AdminGraphScale'
import AdminFallbackSettings from './components/admin/AdminFallbackSettings'


// FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
// LOAD ICONS HERE
import { faSpinner, faPlay, faForward, faBackward, faPause, faTrashAlt, faKey, faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons'
import CheckAlarm from './components/alarm/CheckAlarm';
import UpdateAlarmComment from './components/alarm/UpdateAlarmComment';

// Add icons
library.add([faSpinner, faPlay, faForward, faBackward, faPause, faTrashAlt, faKey, faPencilAlt, faTimes])

const ProtectedRoute = ({ isLogged, isAllowed, ...props }) => {

	if (isLogged === true) {

		if (isAllowed === true) {
			return <Route {...props} />
		}
		else {
			return <Redirect to="/" />
		}
	}
	else {
		return <Redirect to="/auth/login" />
	}
};

const checkRole = (user, allowRoles) => {

	if (user !== null && user.role !== null && user.role !== undefined) {
		// allow always SuperAdmin or role must be in the access list
		if (user.role.name === 'SuperAdmin' || allowRoles.indexOf(user.role.name) !== -1) {
			return true
		}
	}

	return false
}

class App extends Component {

	render() {
		const { loggedIn, user } = this.props

		let _this = this
		const service = new Service(axios)

		let isRefreshing = false;
		let failedQueue = [];

		const processQueue = (error, token = null) => {
			failedQueue.forEach(prom => {
				if (error) {
					prom.reject(error);
				} else {
					prom.resolve(token);
				}
			})

			failedQueue = [];
		}

		service.register({
			onRequest(config) {
				const { host, protocol, port, testingLocal} = Config

				if(testingLocal){
					config.url = config.url.indexOf(protocol + '//' + host + ':' + port + '/') === -1 ? protocol + "//" + host + ":" + port + "/" + config.url : config.url
				}
				else {
					const localhostURL = `${window.location.protocol}://${window.location.host}:${window.location.port}/`
					const serverURL = `${protocol}://${host}:${port}/`
	
					if(localhostURL !== serverURL){
						config.url = config.url.replaceAll(localhostURL, "")
						config.url = serverURL + config.url.replaceAll(serverURL, "")
					}
					else {
						config.url = localhostURL + config.url.replaceAll(localhostURL, "")
					}
				}
				

				if (_this.props.tokens !== undefined && _this.props.tokens !== null) {
					config.headers = {
						Authorization: _this.props.tokens.token_type + " " + _this.props.tokens.access_token,
						...config.headers
					};
				}
				else {
					config.headers = {
						authorization: "",
						...config.headers
					};
				}

				return config;
			},
			onResponseError(error) {

				const originalRequest = error.config;

				if (error.response.status === 401 && !originalRequest._retry && _this.props.tokens !== undefined && _this.props.tokens !== null) {
					console.log(error.response)
					if (isRefreshing) {
						return new Promise(function (resolve, reject) {
							failedQueue.push({ resolve, reject })
						}).then(token => {
							originalRequest.headers['Authorization'] = 'Bearer ' + token;
							return axios(originalRequest);
						}).catch(err => {
							return err
						})
					}

					originalRequest._retry = true;
					isRefreshing = true;

					const refresh_token = _this.props.tokens.refresh_token

					return new Promise(function (resolve, reject) {
						axios.post('api/auth/refresh', { refresh_token })
							.then((res) => {
								_this.props.refreshToken(res)

								axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.access_token;
								originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;

								processQueue(null, res.data.access_token);
								resolve(axios(originalRequest));
							})
							.catch((err) => {
								processQueue(err, null);
								reject(err);
								_this.props.logout();

							})
							.then(() => { isRefreshing = false })
					}).then((res) => {
						return res
					})
				}
				return Promise.reject(error);
			}
		});

		return (

			<BrowserRouter>
				<div className="App">
					<Navbar />

					{/* Logout automatic after 10 min */}
					<Timeout />

					<Switch>
						<ProtectedRoute path="/" component={Dashboard} isLogged={loggedIn} isAllowed={true} exact={true} />
						<Route path='/auth/login' component={Login} exact={true} />
						<Route path='/contacts' component={Contacts} exact={true} />
						<Route path='/info' component={Info} exact={true} />
						<ProtectedRoute path='/charts' component={DustCharts} isLogged={loggedIn} isAllowed={true} exact={true} />
						<ProtectedRoute path="/user/changepassword" component={ChangePassword} isLogged={loggedIn} isAllowed={true} exact={true} />
						<ProtectedRoute path='/admin' component={AdminUsers} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin'])} exact={true} />
						<ProtectedRoute path="/export" component={Export} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin'])} exact={true} />
						<ProtectedRoute path='/admin/alarms' component={AdminAlarmSettings} isLogged={loggedIn} isAllowed={checkRole(user, ['SuperAdmin', 'Admin'])} exact={true} />
						<ProtectedRoute path='/admin/graph-scale' component={AdminGraphScale} isLogged={loggedIn} isAllowed={checkRole(user, ['SuperAdmin', 'Admin'])} exact={true} />
						<ProtectedRoute path='/admin/adduser' component={AdminAddUser} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin'])} exact={true} />
						<ProtectedRoute path="/admin/user/:id/edit" component={AdminEditUser} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin'])} exact={true} />
						<ProtectedRoute path="/admin/changepassword/:id" component={ChangePassword} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin'])} exact={true} />
						<ProtectedRoute path='/admin/fallback' component={AdminFallbackSettings} isLogged={loggedIn} isAllowed={checkRole(user, ['SuperAdmin'])} exact={true} />
						<ProtectedRoute path="/alarms" component={AlarmList} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin','User+'])} exact={true} />
						<ProtectedRoute path="/alarms/check/:id" component={CheckAlarm} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin','User+'])} exact={true} />
						<ProtectedRoute path="/alarms/edit/:id" component={UpdateAlarmComment} isLogged={loggedIn} isAllowed={checkRole(user, ['Admin','User+'])} exact={true} />


					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loggedIn: state.auth.loggedIn,
		user: state.auth.user,
		tokens: state.auth.tokens
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		refreshToken: (res) => dispatch(refreshToken(res)),
		logout: () => dispatch(logout())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
